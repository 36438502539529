import { format } from "date-fns";
import { t } from "i18next";
import { get, isEmpty } from "lodash";

const formatFiltersObj = (filters) => {
  const { dateRange, patient_type } = filters;

  const categories = {
    ai_tags: {
      present_findings: {
        tags: [],
        join: "",
      },
      absent_findings: {
        tags: [],
        join: "",
      },
    },
    workflow_tags: {
      present_findings: {
        tags: [],
        join: "",
      },
      absent_findings: {
        tags: [],
        join: "",
      },
    },
    lab_results: {},
    patient_type: {},
  };
  const genders = [];
  const ageGroups = [];
  const sites = [];
  const dicom_upload = [];
  const report_editors = [];
  const symptoms = get(filters, ["symptoms", "tags", "dropdownMenuTags"], []);
  const risk_groups = get(filters, ["risk_groups", "tags", "dropdownMenuTags"], []);
  const report_status = get(filters, ["report_status", "tags", "tags"], []);
  const modalities = get(filters, ["modalities", "tags", "tags"], []);
  const questionnaire_response = get(
    filters,
    ["questionnaire_response", "tags", "tags"],
    []
  );
  const use_case = get(filters, ["use_case", "tags", "tags"], []);

  // Adding a filter on patient additional. No manipulation is required here as this is only applied from the BE as saved filter.
  const additionalFilter = get(filters, ["additional"], null);

  Object.keys(filters).forEach((category) => {
    if (category === "dateRange") return;

    if (category === "site") {
      sites.push([...filters["site"]["tags"]["dropdownMenuTags"]]);
      return;
    }

    if (category === "report_edited_by") {
      report_editors.push(
        ...get(filters, ["report_edited_by", "tags", "dropdownMenuTags"], [])
      );
      return;
    }

    if (category === "dicom_upload") {
      get(filters, ["dicom_upload", "tags", "tags"], []).map((status) => {
        if (status === "pending") {
          dicom_upload.push(1);
        } else if (status === "negative") {
          dicom_upload.push(2);
        } else if (status === "positive") {
          dicom_upload.push(3);
        }
      });
      return;
    }

    if (
      category === "risk_groups" ||
      category === "report_status" ||
      category === "modalities" ||
      category === "use_case" ||
      category === "questionnaire_response"
    ) {
      return;
    }

    if (category === "symptoms") {
      return;
    }

    if (
      category === "present_workflow_findings" ||
      category === "absent_workflow_findings"
    ) {
      const values = get(filters, [category, "tags", "tags"], []);
      const join = get(filters, [category, "join"], null);

      let updated_category = category;
      if (category === "present_workflow_findings") {
        updated_category = "present_findings";
      }

      if (category === "absent_workflow_findings") {
        updated_category = "absent_findings";
      }

      values.forEach((selectedTags) => {
        categories?.["workflow_tags"]?.[updated_category]?.["tags"]?.push(selectedTags);
      });
      categories["workflow_tags"][updated_category]["join"] = join;
      return;
    }

    const tags = get(filters, [category, "tags"], null);
    if (!tags) return;

    Object.keys(tags).forEach((tagType) => {
      // Only positive findings must be shown
      if (tagType === "tags" || tagType === "dropdownMenuTags") {
        let values = tags[tagType];
        if (
          (category === "present_findings" || category === "absent_findings") &&
          tagType === "dropdownMenuTags"
        ) {
          values = Object.values(values).flat();
        }
        values.forEach((selectedTags) => {
          categories?.["ai_tags"]?.[category]?.["tags"]?.push(selectedTags);
        });
      } else if (tagType === "dropdownTags") {
        tags.dropdownTags.forEach((tag) => {
          if (!isEmpty(tag[Object.keys(tag)[0]])) {
            categories[category][Object.keys(tag)[0]] = [...tag[Object.keys(tag)[0]]];
          }
        });
      }
    });

    const join = get(filters, [category, "join"], null);
    if (join) {
      categories["ai_tags"][category]["join"] = join;
    }
  });

  patient_type?.tags?.tags.forEach((tag) => {
    if (tag === "male") {
      genders.push("0");
    } else if (tag === "female") {
      genders.push("1");
    } else if (tag === "other") {
      genders.push("2");
    } else {
      ageGroups.push(tag);
    }
  });
  const formattedFilters = {
    start_study_date: get(dateRange, [0], ""),
    end_study_date: get(dateRange, [1], ""),
    ai_tags: categories.ai_tags,
    workflow_tags: categories.workflow_tags,
    lab_results: categories.lab_results,
    risk_groups: risk_groups.join(","),
    symptoms: symptoms.join(","),
    report_status: report_status.join(","),
    modalities: modalities.join(","),
    gender: genders.join(","),
    age_groups: ageGroups.join(","),
    ai_inference: dicom_upload.join(","),
    report_edited_by: report_editors.map((i) => i.email).join(","),
    source: sites.join(","),
    use_case: use_case.join(","),
    questionnaire_response: questionnaire_response.join(","),
    additional: additionalFilter,
  };
  return formattedFilters;
};

const getFilterString = (filtersData, labelMappings, allFilters, sources) => {
  const filtersList = [];
  filtersData &&
    Object.keys(filtersData).map((filterKey) => {
      const filter = filtersData[filterKey];
      if (filterKey === "dateRange") {
        if (filter[0] !== null || filter[1] !== null)
          filtersList.push(
            format(new Date(filter[0]), "PP") + " - " + format(new Date(filter[1]), "PP")
          );
      } else if (filterKey === "symptoms" || filterKey === "risk_groups") {
        const { tags } = filter;
        tags.dropdownMenuTags.map((tag) => {
          let label;
          allFilters.map((filterObj) => {
            if (Object.keys(filterObj)[0] === filterKey) {
              const dropdownMenuTags = get(filterObj, [
                filterKey,
                "tags",
                "dropdownMenuTags",
              ]);
              dropdownMenuTags.map((tagObj) => {
                if (tagObj.id === tag) label = tagObj.choice;
              });
            }
          });
          filtersList.push(label);
        });
      } else if (filterKey === "report_edited_by") {
        const tags = get(filter, ["tags", "dropdownMenuTags"], []);
        filtersList.push(tags.map((i) => i.email));
      } else if (filterKey === "site") {
        const { tags } = filter;

        const sourceMap = {};
        sources.forEach((source) => {
          sourceMap[source.uuid] = source.name;
        });

        tags?.dropdownMenuTags?.map((tag) => filtersList.push(sourceMap[tag]));
      } else {
        const { tags } = filter;
        tags?.dropdownTags?.map((tag) =>
          filtersList.push(t(`mapping.${Object.keys(tag)[0]}`))
        );
        let _dropdownMenuTags = tags?.dropdownMenuTags;
        if (filterKey === "present_findings" || filterKey === "absent_findings") {
          _dropdownMenuTags = Object.values(tags?.dropdownMenuTags).flat();
        }
        _dropdownMenuTags?.map((tag) => filtersList.push(t(`mapping.${tag}`)));
        tags?.tags?.map((tag) => filtersList.push(t(`mapping.${tag}`)));
      }
    });

  return filtersList.filter((element) => element?.length).join(", ");
};

export { formatFiltersObj, getFilterString };
